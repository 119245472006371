import React, { useState } from "react"
import Layout from "../../chunks/layout"
import { Helmet } from "react-helmet"
import Main from "../../components/main"
import Pricing from "../../components/pricing"
import GuaranteeSection from "../../components/guaranteesection"
import Features from "../../components/features"
import Testimonials from "../../components/testimonials"
import Faqs from "../../components/faqs"
import HowItWorks from "../../components/howitworks"

const TikTok = props => {
  const [productName, setProductName] = useState(props.productName)
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Tiktok | Boostik</title>
        <link rel="canonical" href="https://boostik.io/" />
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <Main
        viewName="tiktok"
        title="Accelerate your growth on TikTok"
        subTitle=""
        description="Start spreading your content. Get more likes and become famous on TikTok."
      />
      <div className="w-full bg-gray">
        <div className="background-gray-wave-separator-rotated"></div>
      </div>
      <div className="section-container bg-gray">
        <div className="section-content">
          <div className="flex justify-center items-center">
            <button
              className={`pricing-menu-button ${
                productName === "followers" &&
                `pricing-menu-button-selected color-tiktok shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("followers")
              }}
              onKeyDown={() => {
                setProductName("followers")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-user text-3xl"></i>
              </div>
              Followers
            </button>
            <button
              className={`pricing-menu-button ${
                productName === "likes" &&
                `pricing-menu-button-selected color-tiktok shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("likes")
              }}
              onKeyDown={() => {
                setProductName("likes")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-heart text-3xl"></i>
              </div>
              Likes
            </button>
            <button
              className={`pricing-menu-button ${
                productName === "views" &&
                `pricing-menu-button-selected color-tiktok shadow-lg`
              } w-28`}
              onClick={() => {
                setProductName("views")
              }}
              onKeyDown={() => {
                setProductName("views")
              }}
              role="button"
              tabIndex={0}
            >
              <div>
                <i className="bx bx-show text-3xl"></i>
              </div>
              Views
            </button>
          </div>
        </div>
        <Pricing
          viewName="tiktok"
          categoryName="tiktok"
          productName={productName}
        />
      </div>
      <GuaranteeSection viewName="tiktok" />
      <div className="section-container background-section-gray">
        <Features viewName="tiktok" categoryName="tiktok" />
      </div>
      <HowItWorks viewName="tiktok" categoryName="tiktok" />
      {/* <div className="w-full flex flex-col justify-center background-gradient-tiktok">
        <div
          className={`background-${
            props.viewName !== "home" ? "gray" : "white"
          }-wave-separator-rotated`}
        ></div>
        <div className="container mx-auto text-center p-10">
          <div className="section-title color-white">
            Why buy <b>Tik Tok Services</b>?
          </div>
        </div>
        <div className="container mx-auto p-10 text-white">
          People who produce original content for Tiktok and upload it regularly
          can achieve high success rates. Considering that it is a platform
          where you share short videos, having a lot of views is an essential
          factor. But the competition gets harder, and now it is more
          challenging to be visible on TikTok. If your follower count is not
          enough for you or does not meet your expectations, you can buy TikTok
          followers.
          <p className="mt-10">
            Lots of views will increase your chances to take up a position in
            Discover. TikTok views will make your post to be suggested to TikTok
            users. More views mean more suggestions. Start spreading your
            content by buying views for your TikTok videos.
          </p>
        </div>
        <div className="background-gray-wave-separator"></div>
      </div> */}
      <div className="background-section-gray">
        <div className="container mx-auto text-center">
          <div className={`section-title color-tiktok mt-10`}>
            Some Reviews and Customer Feedback
          </div>
          <div>
            <Testimonials
              categoryName="tiktok"
              testimonialsList={[
                {
                  name: "Dominic Smith",
                  review: 5,
                  comment:
                    "“Fastest service on the market. Although I was a little hesitant at first, now I don’t regret it. I’ll repeat with them”.",
                  userImage: "user13",
                },
                {
                  name: "Michael Wright",
                  review: 4,
                  userImage: "user5",
                  comment:
                    "“I would definitely repeat! Likes are delivered instantly to my posts after the purchase”.",
                },
                {
                  name: "Daniel Jones",
                  review: 4,
                  userImage: "user15",
                  comment: "“Best  customer service and supa fast”.",
                },
                {
                  name: "Will Thompson",
                  review: 4.5,
                  userImage: "user6",
                  comment:
                    "“I suggest buying fans on Boostik, faster than I thought and it really helps to grow your account.”",
                },
                {
                  name: "Ben Williams",
                  review: 5,
                  userImage: "user20",
                  comment: "“Great tool, it made my profile grow!”",
                },
                {
                  name: "Chloe Robinson",
                  review: 5,
                  userImage: "user2",
                  comment:
                    "“I had some doubts and they solved it super fast, I’m very satisfied”.",
                },
                {
                  name: "Taylor Evans",
                  review: 4,
                  userImage: "user12",
                  comment: "“Very well, I recommend it”.",
                },
                {
                  name: "Ana Perez",
                  review: 4.5,
                  userImage: "user21",
                  comment:
                    "“It’s my third time buying these services and honestly I’m very satisfied and quite surprised”.",
                },
              ]}
            />
          </div>
        </div>
      </div>
      <Faqs
        listOfFaqs={[
          {
            title: "WHEN WILL MY ORDER START?",
            description:
              "The start time for all services can vary and is listed on the Service Description. Some services have an instant start time and others may have a start time that is delayed up to 24 hours. Please be sure you review the Service Description for the service you are interested in before placing the order. Please note that for YouTube services, YouTube doesn’t update all stats in real-time so it can take up to 6 hours for you to see the effects from your order.",
          },
          {
            title: "IS THIS SAFE FOR MY ACCOUNT?",
            description:
              "Yes, it’s 100% safe to use Boostik. All of our social media marketing services comply fully with the terms, conditions and guidelines of whichever platform we’re dealing with. There is absolutely no chance whatsoever that your account will be penalized in any way. We constantly monitor all of the major social media players for updates and adapt our services accordingly. This means that you will always be one step ahead and your account will never be in jeopardy.",
          },
          {
            title: "HOW FAST WILL I RECEIVE THE SERVICES?",
            description:
              "Services for TikTok are so fast. We can confidently assure you that in less than half an hour you will have started to see your stats increase.The longest you will ever have to wait for any service will be 24 hours. Please note that delivery times are estimated and may vary during busier periods. Within moments of your purchase, you’ll start seeing people liking your post so naturally that the TikTok algorithm will assume it's trendy and promote your account to the ForYou section. You can select the time it takes to get all likes you ordered, from arriving instantly to taking up to a few hour In case you need more information about our delivery times, just send us an email, and we’ll be glad to resolve your doubts.",
          },
          {
            title: "DO YOU NEED MY PASSWORD?",
            description:
              "It is not necessary to have your password in order to deliver a high-quality service. All we need is a link to the content you want to promote to get started. We do NOT require and will never ask for your password or account access. All we need is your Social Media URL/username and your email so that we can contact you regarding your order process. By doing so, we eliminate the possibility of your Social Media profile being breached by any third parties.",
          },
          {
            title: "WHICH PACKAGE IS RIGHT FOR ME?",
            description:
              "We constantly analyse TikTok, thanks to this we can offer you the 'Best Value' option at an ultra-competitive price. As for the right package for you, this will depend on your requirements. Remember that we are at your disposal in case you need further help, send us an email and we will assist you!",
          },
          {
            title: "WHAT PAYMENT METHODS DO YOU ACCEPT?",
            description:
              "At the moment we accept payments through credit and debit cards.",
          },
          {
            title: "CAN I GET A REFUND?",
            description:
              "Your order is eligible for refund if it has not been completed yet. If we fail to deliver your order, you can request for a full refund. Please note different packages have different delivery periods. ",
          },
          {
            title: "CAN I CANCEL MY ORDER?",
            description:
              "Once an order has been placed it is not always possible to cancel it. Many of our services have very fast delivery speeds, therefore making it impossible to cancel the order before it’s delivered. In the event that you need to cancel an order please contact our Customer Support Service and we will do our best to stop it as soon as possible. We do not issue refunds for services that have been delivered.",
          },
          {
            title: "MORE QUESTIONS?",
            description:
              "If you cannot find your answer here, you can send us an email. Our Customer Support Team will be happy to help you!",
          },
        ]}
        viewName="tiktok"
      />
    </Layout>
  )
}

export default TikTok
